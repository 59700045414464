.forRating {
  display: flex;
  align-items: center;
  justify-content: center;

  .star {
    margin-right: 2px;

    &.full {
      color: #f39c12; /* Full star color (gold) */
    }

    &.half {
      background: linear-gradient(90deg, #f39c12 50%, #ccc 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.empty {
      color: #ccc; /* Empty star color (gray) */
    }
  }
}
